import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import TracerOf from '../../containers/tracerOf';
import { imagesRepo } from '../../config';

const DetailOf = ({ data }) => {
    const [of, setOf] = useState('');
    const [open, setOpen] = useState(false);
    let previousBijouterie = null;

    const tracerOf = (of) => {
        setOf(of);
        
        setOpen(true);
    };

    return (
        <>
            {data.length > 0 && data.map((of) => {
                const currentBijouterie = of.bijouterie;
                const displayBijouterie = currentBijouterie !== previousBijouterie;

                previousBijouterie = currentBijouterie;

                return (
                    <div key={of.num_sachet} style={{ textAlign: 'center' }} onDoubleClick={() => tracerOf(of)}>
                        {displayBijouterie && <h5 style={{ paddingTop: '10px' }}>{currentBijouterie}</h5>}
                        <div className="detailOfDiv" style={{
                            ...(!displayBijouterie && { borderTop: '1px solid #ccc' }),
                        }}>
                            <div style={{ marginRight: '10px' }}>
                                <Image key={of.num_sachet} src={`${imagesRepo}thumbs.php?src=${of.image}&size=50`} rounded />
                            </div>
                            <div style={{ color: of.date_l_imp === "1" ? 'red' : of.statut === "575" ? '#22BB33' : 'inherit', }}>
                                <div>
                                    {of.lestade === "SAV" ? `${of.ref_cv} : ${of.Notes}` : `${of.ref_cv} : ${of.ref}`}
                                </div>

                                <div>
                                    {`N°OF: ${of.num_sachet} - ${of.lestatut}`}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogContent>
                    <TracerOf of={of} open={open} />
                </DialogContent>
                <DialogActions>
                    <DialogTitle id="form-dialog-title">Tracer OF</DialogTitle>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DetailOf;
