import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';
import { localization, meekroDB } from '../../../config';
import Axios from 'axios';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const TableFinalise = ({ dataOuvrier, setDataOuvrier, dataSerti, setDataSerti, addForm }) => {
    const updateRow = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            try {
                const data = {
                    table: 'ouvrier',
                    primaryKey: 'id_ligne',
                    id: newData.id_ligne,
                    poids_d: newData.poids_d,
                    poids_r: newData.poids_r,
                    temps: newData.temps,
                }
                Axios.put(`${meekroDB}/general.php`, data)
                    .then((response) => {
                        //TODO REFRESH
                        const updatedData = dataOuvrier.map(item => {
                            if (item.id_ligne === newData.id_ligne) {
                                return {
                                    ...item,
                                    poids_d: newData.poids_d,
                                    poids_r: newData.poids_r,
                                    temps: newData.temps,
                                };
                            }
                            return item;
                        });
                        setDataOuvrier(updatedData);
                        resolve();
                    })
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
                resolve();
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    }
    const updateRowSerti = (newData, oldData) => {
        
        return new Promise((resolve, reject) => {
            try {
                const data = {
                    table: 'serti',
                    primaryKey: 'id_sert',
                    id: newData.id_sert,
                    pds_d: newData.pds_d,
                    pds_r: newData.pds_r,
                    prix: newData.prix,
                }
                Axios.put(`${meekroDB}/general.php`, data)
                    .then((response) => {
                        //TODO REFRESH
                        const updatedData = dataSerti.map(item => {
                            if (item.id_serto === newData.id_serto) {
                                return {
                                    ...item,
                                    pds_d: newData.pds_d,
                                    pds_r: newData.pds_r,
                                    prix: newData.prix,
                                };
                            }
                            return item;
                        });
                        setDataSerti(updatedData);
                        resolve();
                    })
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
                resolve();
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    }

    const handleSupprimer = (index) => {
        return new Promise((resolve, reject) => {
            try {
                const datat = {
                    id: index,
                    primaryKey: 'id_ligne',
                    table: 'ouvrier'
                }
                Axios.delete(`${meekroDB}/general.php`, { params: datat })
                    .then((response) => {
                        const updatedData = dataOuvrier.filter(item => item.id_ligne !== index);
                        setDataOuvrier(updatedData);
                        resolve();
                    })
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };
    const handleSupprimerSerti = (index) => {
        return new Promise((resolve, reject) => {
            try {
                const datat = {
                    id: index,
                    primaryKey: 'id_sert',
                    table: 'serti'
                }
                Axios.delete(`${meekroDB}/general.php`, { params: datat })
                    .then((response) => {
                        const updatedData = dataSerti.filter(item => item.id_sert !== index);
                        setDataSerti(updatedData);
                        resolve();
                    })
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5" align="center" gutterBottom>
                        Atelier
                    </Typography>
                    <MaterialTable
                        columns={[
                            { title: 'Joaillier', field: 'label', editable: 'never', align: 'center' },
                            { title: 'Poids D', field: 'poids_d' },
                            { title: 'Poids R', field: 'poids_r' },
                            { title: 'Temps', field: 'temps' },
                            {
                                title: '',
                                field: 'actions',
                                editable: 'never',
                                render: (rowData) => (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={(e) => { e.stopPropagation(); handleSupprimer(rowData.id_ligne) }}
                                    >
                                        Supprimer
                                    </Button>

                                ),
                                width: 10 // Réduire la largeur de la colonne
                            },
                        ]}
                        data={dataOuvrier}
                        options={{
                            minBodyHeight: 200,
                            paging: false,
                            search: false,
                            toolbar: true, // Afficher la barre d'outils
                        }}
                        localization={localization}
                        components={{
                            Toolbar: props => (
                                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 15 }}>
                                    <IconButton onClick={() => addForm('joaillier')}>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            ),
                        }}
                        editable={{ onRowUpdate: updateRow }}
                    />
                </CardContent>
            </Card>
            <Card variant="outlined" style={{ marginTop: '15px' }}>
                <CardContent>
                    <Typography variant="h5" align="center" gutterBottom>
                        Serti
                    </Typography>
                    <MaterialTable
                        columns={[
                            { title: 'Sertisseur', editable: 'never', field: 'label' },
                            { title: 'OF', editable: 'never', field: 'of_serto' },
                            { title: 'Poids D', field: 'pds_d' },
                            { title: 'Poids R', field: 'pds_r' },
                            { title: 'Prix', field: 'prix' },
                            {
                                title: '',
                                field: 'actions',
                                editable: 'never',
                                render: (rowData) => (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={(e) => { e.stopPropagation(); handleSupprimerSerti(rowData.id_sert) }}
                                    >
                                        Supprimer
                                    </Button>

                                ),
                                width: 10 // Réduire la largeur de la colonne
                            },
                        ]}
                        data={dataSerti}
                        options={{
                            minBodyHeight: 200,
                            paging: false,
                            search: false,
                            toolbar: true,
                        }}
                        localization={localization}
                        components={{
                            Toolbar: props => (
                                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 15 }}>
                                    <IconButton onClick={() => addForm('serti')}>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            ),
                        }}
                        editable={{ onRowUpdate: updateRowSerti }}
                    />
                </CardContent>
            </Card>
        </>
    );
};

export default TableFinalise;
